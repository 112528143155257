import {CHANGE_IMG_PATH, TOOGLE_SHOW_DESCRIPTION, ADD_PLACES_VALUES} from './places.types'

export const changeImgPath = img => {
    return {
        type: CHANGE_IMG_PATH,
        payload: img,
    }
}

export const toogleShowDescription = () => {
    return {
        type: TOOGLE_SHOW_DESCRIPTION,
    }
}

export const addPlacesValues = (values) => {
    return {
        type: ADD_PLACES_VALUES,
        payload: values
    }
}