import React from 'react';
import { Link } from 'react-router-dom'
import img from '../../../assets/imgs/boat_drone.jpg';

const Places = () => (
    <Link to='/pontos' className="divePlaces card">
        <div style={{backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center',
               backgroundRepeat: 'no-repeat', width: '200px', height: '150px', borderRadius: '15px'}} />
        <div className="infos">
            <p className="title">PONTOS DE MERGULHO/<br />VALORES</p>
            <p>Ubatuba conta com excelentes pontos de mergulho.</p>
            {/* <Link to='/pontos'>Saiba Mais</Link> */}
        </div>
    </Link>
);

export default Places;