import React from 'react';

const ContactPage = () => {
    return (
        <div id="contact">
            <p className="title">Contato</p>
            <div className="contact-infos-container">
                <p>
                    <img src={require('../../assets/imgs/whatsapp.png')} alt="" /> (12) 997673040
            </p>
                <p><a href="mailto:scubatuba@scubatuba.com.br" className="contact-elem" ><img src={require('../../assets/imgs/o-email.png')} alt="" />scubatuba@scubatuba.com.br</a></p><br />
            </div>
        </div>
    );
};

export default ContactPage;