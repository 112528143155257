import { CHANGE_IMG_PATH, TOOGLE_SHOW_DESCRIPTION, ADD_PLACES_VALUES } from './places.types'

const initialState = {
    hide: true,
    imgUrl: 'anchieta 2.jpg',
    values: {}
}

const placesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_IMG_PATH:
            return {
                ...state,
                imgUrl: action.payload
            }
        case TOOGLE_SHOW_DESCRIPTION:
            return {
                ...state,
                hide: !state.hide
            }
        case ADD_PLACES_VALUES:
            return {
                ...state,
                values: action.payload
            }
        default: return state
    }
}

export default placesReducer