import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'

import logo from '../../assets/imgs/logo1.PNG'

const Header = () => {

    const [open, setOpen] = useState(false)

    const node = useRef()

    const handleClickOutside = e => {

        if (node.current.contains(e.target)) {
            return
        }

        setOpen(false)
    }

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [open])

    return (
        <div className="header">
            <Link to='/'>
                <img className='logo' src={logo} alt="logo" />
            </Link>
            <div className="header-topics">
                <p>
                    <a href="/#whoWeAre">Quem somos</a>
                </p>
                <p>
                    <a href="/#services">Serviços</a>
                </p>
                <p>
                    <Link to='/discover-scuba'>Discover Scuba</Link>
                </p>
                <p>
                    <Link to='/cursos'>Cursos</Link>
                </p>
                <p>
                    <Link to='/pontos'>Pontos de Mergulho</Link>
                </p>
                <p>
                    <a href='/#contact'>Contato</a>
                </p>
                <a className="facebook-img" href="https://www.facebook.com/ScubatubaMergulhoETurismo/" target="_blank">
                    <img src={require('../../assets/imgs/facebook.png')} /></a>
            </div>

            <div ref={node} className="mobile-header-menu">
                <img onClick={() => setOpen(!open)} className="hamburguer-menu" src={require('../../assets/imgs/menu.png')} />
                {open &&
                    <div className="mobile-menu">
                        <p>
                            <a onClick={() => setOpen(!open)}href="/#whoWeAre">Quem somos</a>
                        </p>
                        <p>
                            <a onClick={() => setOpen(!open)} href="/#services">Serviços</a>
                        </p>
                        <p>
                            <Link onClick={() => setOpen(!open)} to='/discover-scuba'>Discover Scuba</Link>
                        </p>
                        <p>
                            <Link onClick={() => setOpen(!open)} to='/cursos'>Cursos</Link>
                        </p>
                        <p>
                            <Link onClick={() => setOpen(!open)} to='/pontos'>Pontos de Mergulho</Link>
                        </p>
                        <p>
                            <a onClick={() => setOpen(!open)} href='/#contact'>Contato</a>
                        </p>
                        <a onClick={() => setOpen(!open)} className="facebook-img" href="https://www.facebook.com/ScubatubaMergulhoETurismo/" target="_blank">
                            <img width="25" src={require('../../assets/imgs/facebook.png')} /></a>
                    </div>
                }
            </div>
        </div>
    )
}
export default Header;