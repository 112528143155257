import { TOOGLE_SHOW_DESCRIPTION, ADD_COURSES_VALUES} from './courses.types'

export const toogleShowDescription = () => {
    return {
        type: TOOGLE_SHOW_DESCRIPTION,
    }
}

export const addCoursesValues = (data) => {
    return {
        type: ADD_COURSES_VALUES,
        payload: data
    }
}