import React from 'react';
import { Link } from 'react-router-dom'

import img from '../../../assets/imgs/mergulho.jpg';

const Scuba = () => (
    <Link to='/discover-scuba' className="scuba-dive card">
        {/* <img src={img} alt="scuba" /> */}
        <div style={{backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center',
               backgroundRepeat: 'no-repeat', width: '200px', height: '150px', borderRadius: '15px'}} />
        <div className="infos">
            <p className="title">DISCOVER SCUBA</p>
            <p>Mergulho na cia. de
            um profissional da
            área para quem
                    não tem curso</p>
            {/* <Link to='/discover-scuba'>Saiba Mais</Link> */}
        </div>
    </Link>
);

export default Scuba;