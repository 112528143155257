import { TOOGLE_SHOW_DESCRIPTION, ADD_COURSES_VALUES } from './courses.types'

const initialState = {
    hide: true,
    values: {}
}

const coursesReducer = (state = initialState, action) => {
    switch(action.type){
        case TOOGLE_SHOW_DESCRIPTION:
            return {
                ...state,
                hide: !state.hide
            }
        case ADD_COURSES_VALUES: 
            return {
                ...state,
                values: action.payload
            }
        default: return state
    }
}

export default coursesReducer