import React from 'react';
import { Link } from 'react-router-dom';

import img from '../../../assets/imgs/aula_mar.jpg';

const Courses = () => {
    return (
        <Link to='/cursos' className="classNamees card">
            {/* <img src={img} alt="cursos" /> */}
            <div style={{
                backgroundImage: `url(teste)`,backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center',
               backgroundRepeat: 'no-repeat', width: '200px', height: '150px', borderRadius: '15px'
            }} />
            <div className="infos">
                <p className="title">CURSOS DE MERGULHO</p>
                <p>Cursos individuais ou em grupos, com instrutores qualificados.</p>
                {/* <Link to='/cursos'>Saiba Mais</Link> */}
            </div>
        </Link>
    );
}
export default Courses;